import React, { useContext, useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import { Link } from "react-scroll";
import PageContext from "../context/context";
import { StaticImage } from "gatsby-plugin-image";

const Hero = () => {
    const { hero } = useContext(PageContext);
    const { title, name, subtitle, cta } = hero;

    const [isDesktop, setIsDesktop] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (window.innerWidth > 769) {
            setIsDesktop(true);
            setIsMobile(false);
        } else {
            setIsMobile(true);
            setIsDesktop(false);
        }
    }, []);

    return (
        <section id="hero" className="jumbotron">
            <Container>
                {name &&
                    <Fade
                        left={isDesktop}
                        bottom={isMobile}
                        duration={1000}
                        delay={500}
                        distance="30px"
                    >
                        <h1 className="hero-title">
                            {title || "We are"}{" "}
                            <span className="text-color-main">{name || ""}</span>
                            <br />
                            {subtitle ||
                                ""}
                        </h1>
                    </Fade>
                }
                <Fade
                    left={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={1000}
                    distance="30px"
                >
                    <p className="hero-cta">
                        <span className="cta-btn cta-btn--hero">
                            {cta &&
                                <Link to="about" smooth duration={1000}>
                                    {cta}
                                </Link>
                            }
                        </span>
                    </p>
                </Fade>
                <Fade
                    left={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={1200}
                    distance="30px"
                >
                    <StaticImage height={200} width={200} src="../images/favicon.png" alt="Costatek Logo" />
                </Fade>
            </Container>
        </section>
    );
};

export default Hero;
