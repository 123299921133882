import React, { useContext, useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import { Container, Row, Col } from "react-bootstrap";
import Title from "./Title/Title";
import PageContext from "../context/context";
import { StaticImage } from "gatsby-plugin-image";
import { Link as LinkScroll } from "react-scroll"

const About = () => {
    const { about } = useContext(PageContext);
    const { img, paragraphOne, paragraphTwo, paragraphThree, cta } = about;

    const [isDesktop, setIsDesktop] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (window.innerWidth > 769) {
            setIsDesktop(true);
            setIsMobile(false);
        } else {
            setIsMobile(true);
            setIsDesktop(false);
        }
    }, []);

    return (
        <section id="about">
            <Container>
                <Title title="About Us" />
                <Row className="about-wrapper">
                    <Col md={6} sm={12}>
                        <Fade bottom duration={1000} delay={600} distance="30px">
                            <div className="about-wrapper__image">
                                <StaticImage src="../images/profileCTK.jpg"
                                    alt="Costatek About Us"
                                    className="rounded shadow-lg"
                                    placeholder="blurred" />
                            </div>
                        </Fade>
                    </Col>
                    <Col md={6} sm={12}>
                        <Fade
                            left={isDesktop}
                            bottom={isMobile}
                            duration={1000}
                            delay={1000}
                            distance="30px"
                        >
                            <div className="about-wrapper__info">
                                <p className="about-wrapper__info-text">
                                    {paragraphOne ||
                                        "P1"}
                                </p>
                                <p className="about-wrapper__info-text">
                                    {paragraphTwo ||
                                        "P2"}
                                </p>
                                <p className="about-wrapper__info-text">
                                    {paragraphThree ||
                                        "P3"}
                                </p>
                                <p className="hero-cta">
                                    <span className="cta-btn cta-btn--resume">
                                        <LinkScroll to="contact" smooth duration={1000}>
                                            {cta || "Contact"}
                                        </LinkScroll>
                                    </span>
                                </p>
                            </div>
                        </Fade>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default About;
