import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/hero';
import About from '../components/about';
import Projects from '../components/projects';
import Contact from '../components/contact';
import ClientLogos from '../components/client-logos';
import { homeData } from '../mock/data';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';


const IndexPage = () => (
  <Layout>
    <SEO title={homeData.title} description={homeData.description}/>
    <Hero />
    <About />
    <Projects />
    <Contact />
    <ClientLogos />
  </Layout>
)

export default IndexPage
