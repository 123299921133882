import React from 'react';
import { Container } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import Title from "./Title/Title";

const ClientLogos = () => {
    return (
        <section id="our-clients">
            <Container>
                <Title title="OUR CLIENTS" />
                <div>
                    <StaticImage width={100} height={50} src="../images/clients/sajomccann.jpg" alt="Goplasa Logo" />
                    <StaticImage width={100} height={50} src="../images/clients/humans.jpg" alt="Goplasa Logo" />
                    <StaticImage width={100} height={50} src="../images/clients/inversso.jpg" alt="Goplasa Logo" />
                    <StaticImage width={100} height={50} src="../images/clients/lisan.jpg" alt="Goplasa Logo" />
                    <StaticImage width={100} height={50} src="../images/clients/jotabequ.jpg" alt="Goplasa Logo" />
                    <StaticImage width={100} height={50} src="../images/clients/costadesk.jpg" alt="Costatek Logo" />
                    <StaticImage width={100} height={50} src="../images/clients/goplasa.jpg" alt="Goplasa Logo" />
                    <StaticImage width={100} height={50} src="../images/clients/perspective.jpg" alt="Goplasa Logo" />
                    <StaticImage width={100} height={50} src="../images/clients/securitas.jpg" alt="Goplasa Logo" />
                    <StaticImage width={100} height={50} src="../images/clients/sylviaguardia.jpg" alt="Goplasa Logo" />
                </div>
            </Container>
        </section>
    );
};

export default ClientLogos;
